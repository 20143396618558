import React, { useState, useEffect } from "react";
// import HomeBanner from "./home/HomeBanner";
import USPSection from "./home/USPSection";
import HomeAbout from "./home/HomeAbout";
import WhyChooseUs from "./home/WhyChoose";
import HomeTeam from "./home/HomeTeam";
import BrandInfo from "./home/BrandInfo";
import HowWeWork from "./home/HowWeWork";
import ProductSection from "./home/ProductSection";
import HeroSection from "./home/HeroSection";
import PortfolioHome from "./home/PortfolioHome";
import ClientLogos from "./services/ClientLogos";
// import HomePortfio from "./home/HomePortfio";
// import TrailImages from "./home/homeBanner/banner";


import { gql } from "graphql-request";
import Hygraph from "../GraphqlClient";  
import "../pages/home/WeeklyDrop";

import { Helmet } from "react-helmet";

// import { PortfolioData } from "../Data";
// import WeeklyDrop from "./home/WeeklyDrop";
import Weekly from "./home/weekly";

const Home = () => {

  const [clients, setClients] = useState([]);

   const [metaTitle, setMetaTitle] = useState("Digital Branding and Creative Agency in Utah, Best Website Revamp Agency in Utah");
    const [metaDescription, setMetaDescription] = useState("ReBrand Gurus is a leading digital creative and branding agency in Utah, specializing in SEO, social media, ORM Services, Web design, online products and mobile application.");
  

  // const [loading, setLoading] = useState(true);



  useEffect(() => {
    const fetchPosts = async () => {
      const query = gql`
        query MyQuery {
          portfolios (where: {websiteLogo: {}}) {
            websiteUrl
            title
            websiteLogo {
              url
            }
          }
        }
      `;
      const query2 = gql`
        query {
          page(where: {slug: "home"}) {
            metaTitle
            metaDescription
            slug
            title
          }
        }
      `;

      try {
        const data = await Hygraph.request(query);
        const metaData = await Hygraph.request(query2);
        const shuffled = data.portfolios.sort(() => 0.5 - Math.random());
        const randomClients = shuffled.slice(0, 20); 
        setClients(randomClients);
        if (metaData.page) {
          setMetaTitle(metaData.page.metaTitle);
          setMetaDescription(metaData.page.metaDescription);
        } else {
          console.error("No data found for the specified slug.");
        }
      } catch (error) {
        console.error("Error fetching client logos:", error);
      } finally {
         
      }
    };

    fetchPosts();
  }, []);



  return (
    <>
      <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroSection />
      <USPSection />
      <HomeAbout />
      <Weekly />
      <WhyChooseUs />
      <HomeTeam />
      <BrandInfo />

      <PortfolioHome />
      <ClientLogos clientLogo={clients} />
      <HowWeWork />
      <ProductSection />
    </>
  );
};

export default Home;
