import React, { useState} from "react";
import Logo from "../../logo.png";

import HamburgerContent from "./HamburgerContent";

import "./header.css";
import { Link } from "react-router-dom";

const Header = ({ history, frameRef }) => {
  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "",
  });
  // State of our button
  const [disabled, setDisabled] = useState(false);

  

  // Toggle menu
  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "open",
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "",
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "open",
      });
    }
  };

  const handleButtonClick = () => {
    disableMenu();
    setState({
      initial: null,
      clicked: !state.clicked,
      menuName: "",
    });
  };

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <>
     
      <header className="header__area"  ref={frameRef}>
        <div className="header__inner">
          <div className="header__logo">
            <div className="logo-primary">
              <Link to={'/'}  id="logo" title="ReBrand Gurus">
                <img
                  data-cursor-size="80px"
                  data-cursor-color="#FF9776"
                  src={Logo}
                  alt="ReBrand Gurus"
                />
              </Link>
            </div>
          </div>

          <div className="header__nav-icon">
            
            <button disabled={disabled}
            className={`menu btn12 ${state.menuName}`}
            onClick={handleMenu} >
              <div className="icon"></div>
            </button>
          </div>

          <div className="header__support">
            <p>
              Support center 
              <a
                href="tel:+1-4353950079"
                data-cursor-size="80px"
                data-cursor-color="#FF9776"
              >
                +1 (435)-395-0079
              </a>
            </p>
          </div>
        </div>
      </header>
      <HamburgerContent state={state} onButtonClick={handleButtonClick} />
     
    </>
  );
};

export default Header;
